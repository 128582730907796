<template>
	<!-- 核心技术 -->
	<div class=''>
		<mzSwiper :image='bannerImg'></mzSwiper>
		<!-- 导航栏 -->
		<div class="flex flex-wrap row-between">
			<div :class="['nav_item mb5',item.id==id?'nav_item_avtive':'']" v-for="(item,i) in CategoryList" :key="i"
				@click="btnTab(item.id,i)">{{item.categoryName}}</div>
		</div>
		<!-- //导航栏 -->
		<!-- 内容区域 -->
		<contentItem :list='list' @gotoD='gotoD' />
	</div>
</template>

<script>
	import { getSkillCategory, getSkills } from '@/api'
	import bannerImgFn from '@/mixin/bannerImg.js'
	export default {
		name: 'coreJiShu',
		data() {
			return {
				id: null,
				CategoryList: [],
				list: [],
				image: '',
				bannerType: 1
			};
		},
		mixins: [bannerImgFn],
		components: {},
		watch: {
			'$route': function(to, from) {
				this.id = this.$route.params.id
				this.getSkillCategory()
			}
		},
		created() {
			this.id = this.$route.params.id
			this.getSkillCategory()
		},

		mounted() {},

		methods: {
			//获取分类列表
			async getSkillCategory() {
				const res = await getSkillCategory()
				this.CategoryList = res.data
				this.id ? '' : this.id = res.data[0].id
				this.getSkills(this.id)
			},
			//获取核心技术列表
			async getSkills(id) {
				const res = await getSkills({ categoryId: id })
				this.list = res.data
			},
			//分类切换
			btnTab(id, i) {
				this.id = id
				this.getSkills(id)
			},
			//接收子组件传的id
			gotoD(id) {
				this.$router.push({
					name: "coreJiShuD",
					params: {
						id,
						CategoryId: this.id,
					},
				})
			}
		}
	}
</script>
<style lang='scss' scoped>
	.nav_item,
	.nav_item_avtive {
		width: 49.3%;
		height: 44px;
		background: #F2F2F2;
		font-size: 12px;
		font-weight: 500;
		color: #787878;
		line-height: 44px;
		text-align: center;
		font-weight: 500;
	}

	.nav_item_avtive {
		color: #000000 !important;
		background: #F7B500 !important;
	}
</style>
